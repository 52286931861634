import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

import { standardWrapper, bodyCopy, headlineOne } from "../Utilities"

const NotFound = styled.div`
  ${standardWrapper};
  text-align: center;

  h1 {
    ${headlineOne};
    width: 100%;
  }

  p {
    ${bodyCopy};
  }
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <NotFound>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </NotFound>
  </Layout>
)

export default NotFoundPage
